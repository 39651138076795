@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    margin: 0;
    font-family: 'Nunito Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #222222;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
iframe#webpack-dev-server-client-overlay{display:none!important}
/* width */
::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ECECEC;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #006D85;
}

.title-color {
    color: #fff;
}
.checkbox-wrapper{
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}
.checkbox-wrapper input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 6px;
    margin-right: 6px;
    border: 1px solid #D3D3D3;
    outline: none;
    cursor: pointer;
}
input.checked {
    background-color: #006D85;
    border-color: #006D85;
    position: relative;
}
input.checked::before {
    content: "\2713";
    font-size: 13px;
    font-weight: 900;
    color: #fff;
    position: absolute;
    right: 4px;
    top: 2px;
}
@layer components {
    .form-group {
        @apply mb-4;
    }

    .form-label {
        @apply mb-1 block text-secondary-300 font-semibold;
    }

    .form-input {
        @apply block p-3 w-full border border-secondary-100 rounded-lg focus:border-primary focus:outline-none placeholder-secondary-200 h-12;
    }

    .menu__item {
        @apply hover:text-primary transition ml-8 text-secondary-300 font-semibold relative
    }


    .menu__item_link {
        @apply py-5 inline-block relative before:w-full before:h-2  before:absolute before:-top-1 before:rounded-b-xl
    }

    .menu__item ul li > a.active {
        @apply text-primary bg-[#D9D9D9]
    }

    .menu__item_link.active {
        @apply text-primary  before:bg-primary
    }

    .tab__item_link {
        @apply bg-white rounded-t-xl text-[#4A4A4A] border border-[#E5E5E5] font-bold pt-2 pb-3 px-5   relative before:w-full before:h-1.5  before:absolute before:bottom-0 before:rounded-t-xl before:left-1/2 before:-translate-x-1/2
    }

    .tab__item_link.active {
        @apply text-primary  before:bg-primary
    }

    .table {
        @apply w-full  border-separate border-spacing-y-1.5
    }

    .thead .tr {
        @apply border border-b-['#CFD9DB']
    }


    .th {
        @apply py-3.5 px-3  font-semibold uppercase text-secondary-300 text-center whitespace-nowrap min-w-[200px] border-b max-w-[320px]
    }


    .th:first-child {
        @apply text-left
    }

    .td {
        @apply py-3.5 px-3 text-center border-r border-dashed border-r-[#006D85] max-w-[384px] group-hover:bg-[#E6F1F3]  text-sm;
        word-break: break-word;
    }
    .td.w-fit{
        @apply min-w-fit;
    }

    .td:first-child {
        @apply text-left
    }

    .td:first-child span {
        @apply inline-block
    }

    .td:nth-child(2) {
        @apply whitespace-nowrap
    }
    .wrap .td:nth-child(2) {
        @apply whitespace-pre-wrap
    }

    .td:last-child {
        @apply text-center border-r-transparent
    }

    .tbody .tr {
        @apply bg-white mb-[5px]  transition cursor-pointer drop-shadow-tr
    }
    .tbody .tr.selected .td{
        @apply bg-[#E6F1F3]
    }
    .pagination {
        @apply flex justify-end;
    }

    .pagination li {
        @apply text-secondary-300 p-1 min-w-[50px] h-10 text-center rounded-lg  flex justify-center items-center border border-transparent font-semibold;
    }

    .pagination li.selected {
        @apply border-primary text-primary;
    }
}
.custom-style .rs-picker-default .rs-picker-toggle.rs-btn-lg{
    padding: 12px;
    width: calc(100% - 24px);
}
.rs-picker-tree-menu, .rs-anim-fade.rs-anim-in{
    z-index: 999;
}
.rs-tree-node-label{
    line-height: 14px;
    margin: 0;
    padding: 0;
}
.custom-style-tree .rs-picker-default .rs-picker-toggle.rs-btn-lg{
    width: 100%;
}